<template>
  <div class="min-h-screen flex flex-col bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700">
    <!-- Bandeau blanc -->
    <div class="w-full bg-white/95 backdrop-blur-sm py-2 px-4 sm:px-8 flex items-center">
      <div class="flex items-center gap-2">
        <img src="/images/logo.png" alt="WeCoopt" class="h-6 sm:h-8">
        <span class="text-lg sm:text-xl font-bold text-gray-800">WeCoopt</span>
      </div>
      <div class="h-6 w-px bg-gray-200 mx-3 sm:mx-6"></div>
      <p class="text-gray-600 text-sm sm:text-lg font-semibold flex-1 text-center truncate">
        Le recrutement tech, par les techs, pour les techs
      </p>
      <div class="hidden sm:block w-[150px]"></div>
    </div>

    <!-- Contenu principal -->
    <div class="flex-1 flex overflow-hidden">
      <!-- Partie gauche -->
      <div class="w-full sm:w-1/2 flex flex-col justify-center p-4 sm:p-12">
        <div class="max-w-md mx-auto w-full">
          <div class="bg-white/95 backdrop-blur-sm p-6 sm:p-8 rounded-2xl shadow-xl">
            <slot />
          </div>
        </div>
      </div>

      <!-- Partie droite (témoignages) - masquée sur mobile -->
      <div class="hidden sm:block w-1/2 relative overflow-hidden">
        <div class="absolute inset-0 bg-[url('/images/grid-pattern.svg')] opacity-20"></div>
        <div class="relative h-full flex items-center justify-center p-12">
          <div class="max-w-md testimonials-container">
            <div class="animate-scroll">
              <!-- Premier groupe de témoignages -->
              <template v-for="testimonial in testimonials" :key="`first-${testimonial.name}`">
                <div class="bg-white/10 backdrop-blur-sm p-8 mb-12 rounded-2xl border border-white/20">
                  <div class="flex items-center gap-4 mb-4">
                    <UAvatar
                      :src="testimonial.avatar"
                      :alt="testimonial.name"
                      size="lg"
                      class="ring-2 ring-white/50"
                    />
                    <h3 class="text-xl font-semibold text-white">{{ testimonial.name }}</h3>
                  </div>
                  <p class="text-lg text-white/90 leading-relaxed">
                    "{{ testimonial.quote }}"
                  </p>
                </div>
              </template>
              <!-- Duplication des témoignages pour un défilement continu -->
              <template v-for="testimonial in testimonials" :key="`second-${testimonial.name}`">
                <div class="bg-white/10 backdrop-blur-sm p-8 mb-12 rounded-2xl border border-white/20">
                  <div class="flex items-center gap-4 mb-4">
                    <UAvatar
                      :src="testimonial.avatar"
                      :alt="testimonial.name"
                      size="lg"
                      class="ring-2 ring-white/50"
                    />
                    <h3 class="text-xl font-semibold text-white">{{ testimonial.name }}</h3>
                  </div>
                  <p class="text-lg text-white/90 leading-relaxed">
                    "{{ testimonial.quote }}"
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const testimonials = [
  {
    name: 'Léa R.',
    role: 'Développeuse Front-End',
    location: 'Paris',
    avatar: '/images/testimonials/lea-r.png',
    quote: 'Grâce à WeCoopt, mon réseau est devenu un véritable levier de carrière. Chaque connexion que j\'établis ouvre des opportunités pour mes collègues et moi – et en plus, c\'est rémunérateur ! C\'est simple, intuitif, et incroyablement gratifiant !'
  },
  {
    name: 'Claire D.',
    role: 'Product Manager',
    location: 'Marseille',
    avatar: '/images/testimonials/claire-d.jpg',
    quote: 'En tant que femme dans la tech, donner des conseils de carrière fait partie de mon quotidien. Mais avec WeCoopt, ces échanges deviennent une source de revenus ! C\'est une initiative géniale qui valorise l\'entraide au sein de notre industrie.'
  },
  {
    name: 'Thomas G.',
    role: 'Architecte Cloud',
    location: 'Paris',
    avatar: '/images/testimonials/thomas-g.jpg',
    quote: 'En trois clics, je connecte des talents à des opportunités et augmente mes revenus. WeCoopt m\'a donné une raison de plus d\'adorer la tech – je peux aider et être récompensé. C\'est gagnant-gagnant !'
  },
  {
    name: 'Karim Z.',
    role: 'DevOps',
    location: 'Grenoble',
    avatar: '/images/testimonials/karim-z.png',
    quote: 'Je croyais que mon réseau dormait jusqu\'à ce que je découvre WeCoopt. Désormais, je l\'utilise pour recommander des talents, et en prime, je gagne. Un outil précieux pour tout freelance tech !'
  },
  {
    name: 'Camille F.',
    role: 'Responsable Produit',
    location: 'Bordeaux',
    avatar: '/images/testimonials/camille-f.png',
    quote: 'Recommander des profils de confiance et être rémunérée ? WeCoopt a eu une idée de génie ! Cette plateforme rend le processus fluide, simple, et bénéfique pour le monde du freelance. J\'adore !'
  },
  {
    name: 'Nadia T.',
    role: 'Développeuse Back-End',
    location: 'Lille',
    avatar: '/images/testimonials/nadia-t.jpg',
    quote: 'Les missions viennent de techs comme nous, pas de discours formaté d\'ESN. C\'est rassurant de parler avec quelqu\'un qui connaît notre métier et nos attentes. WeCoopt, c\'est le match parfait.'
  },
  {
    name: 'Lucas M.',
    role: 'Consultant Cloud',
    location: 'Paris',
    avatar: '/images/testimonials/lucas-m.png',
    quote: 'Ce que j\'adore chez WeCoopt, c\'est le lien direct avec des profils tech. On parle sans filtre et c\'est fluide, authentique, un vrai gain de temps. C\'est du recrutement fait pour nous, par nous.'
  },
  {
    name: 'Sonia V.',
    role: 'Product Owner',
    location: 'Toulouse',
    avatar: '/images/testimonials/sonia-v.png',
    quote: 'Avec WeCoopt, chaque étape de mise en relation est guidée par un expert tech. Discuter avec quelqu\'un qui comprend réellement nos compétences, ça change tout ! Un énorme plus pour des connexions de qualité.'
  },
  {
    name: 'Amina K.',
    role: 'Ingénieure QA',
    location: 'Strasbourg',
    avatar: '/images/testimonials/amina-k.jpg',
    quote: 'Être en contact direct avec un pro dès le début, ça change tout ! Chez WeCoopt, les discussions sont plus humaines et transparentes. Je sais qu\'on parle avec quelqu\'un qui connaît notre réalité.'
  },
  {
    name: 'Hassan R.',
    role: 'Analyste Sécurité',
    location: 'Paris',
    avatar: '/images/testimonials/hassan-r.png',
    quote: 'WeCoopt permet des échanges directs avec des experts qui captent immédiatement notre jargon et nos besoins. C\'est un bol d\'air frais de pouvoir éviter les circuits traditionnels et rigides.'
  }
]
</script>

<style scoped>
.testimonials-container {
  height: 90vh;
  overflow: hidden;
  position: relative;
  padding: 4rem 0;
}

.animate-scroll {
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

/* Optionnel : pause au survol */
.testimonials-container:hover .animate-scroll {
  animation-play-state: paused;
}

</style>
